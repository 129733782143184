@import '~bootstrap/scss/bootstrap.scss';

.PageContentWidth {
  max-width: 1000px;
}

.horizontal-bar-chart {
  text-align: left;
  width: 100%;
}

.bars-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.bar-row {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.bar-label {
  width: 150px;
  text-align: left;
  margin-right: 10px;
}

.bar-wrapper {
  flex-grow: 1;
}

.bar-value-label-outside {
  text-align: left;
  margin-left: 5px;
  color: black;
  background-color: var(--bs-secondary-bg)
}